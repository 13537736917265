<template>
    <div>
      <van-nav-bar></van-nav-bar>
      <van-image width="100%" height="100%" fit="contain" :src="imgsrc">
      <template v-slot:error>加载失败</template>
      </van-image>
      <van-cell title="福贡县人民医院" value="开始导航" is-link @click="onload"/>
    </div>
</template>

<script>
export default {
    data () {
    return {
      imgsrc: require('@/assets/FGInfo.png')
    }
  },
    methods: {
      onload() {
        window.location.href="https://apis.map.qq.com/uri/v1/marker?marker=coord:26.902263,98.865636;title:福贡县人民医院;addr:云南省怒江傈僳族自治州福贡县康复路10号"
      }
    }
  }
</script>

<style>
</style>
